@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.sliderWrapper {
    width: fit-content;
    height: 38px;
}

.navigationButton {
    font-size: 32px;
    padding: 0;
    margin: 0;

    &[disabled] {
        opacity: 0;
        display: none;
        pointer-events: none;
    }
}

.wrapper {
    // Global needed to override the swiper styles and align the items in the center
    :global(.swiper-wrapper) {
        display: flex;
        align-items: center;
    }
}
